// asdafsfsf

    var $fixed = $('.content-scroller__fixed');

    if ($fixed.length > 0 )
        var initialFixedPosition = $('.content-scroller__fixed').offset().top +70;


var forceSquare = function() {
    $('.force-square').each(function(i , o ){
        $(o).css({ 'min-height' :   $(o).outerWidth()})
    })
}

var fixScroller = function() {
    if ($(window).scrollTop() > initialFixedPosition) {
        $fixed.addClass('is-fixed');
    }
    if ($(window).scrollTop() < initialFixedPosition ) {
        $fixed.removeClass('is-fixed');
    }
}

var colHeight = function() {
    if ($(window).width() > 668) {
        $('.row').each(function(i , row ) {
            var colHeights = []
            $('.columns' , $(this)).each(function(i , col ){
                colHeights.push($(col).height())
            })
            maxColHeight = Math.max.apply(Math,colHeights)
            $('.columns > .section' , $(this)).css('height' , maxColHeight);
        })
    }
}

var parallax = function(selector , ratio) {
    if ($(selector).length > 0) {
        var factor = -1;
        var lastScrollTop = $(window).scrollTop()
        var scrollTransform = 0
        var parallaxInitPos = $(selector).offset().top
        $(window).scroll(function(){
            scrollTop = $(window).scrollTop()
            var parallaxTopPos =  Math.max(scrollTop - parallaxInitPos , 0)
            setTimeout(function() {
                $(selector).css({top: parallaxTopPos*factor*ratio})
            } , 0 )
        })
    }
}

$(document).ready(function() {

    var $menu_parallax = $('.menu__parallax');


    forceSquare()
    colHeight()

    $(window).resize(function() {
        if ($fixed.length > 0 )
            var initialFixedPosition = $('.content-scroller__fixed').offset().top  + 70;
        forceSquare()
    })

    if ($fixed.length > 0 ) {
        $(window).scroll(function(){
            fixScroller();
        })
    }

    if ($(window).width() < 668) {
        $('.menu__parallax__inner').bxSlider({
            controls : false,
            auto : true
        });
    } else {
        var ratio = $('.menu__parallax').height() / $('.menu__content').height();
        //$('.menu__parallax').css({height : $('.menu__content').height()});
        parallax('.menu__parallax__inner' , ratio);
    }

    // $parallaxInitialHeight  = $('.menu__parallax').height();

    // if ($(window).width() > 668) {
    //     $(window).scroll(function(){
    //         if ($menu_parallax.length > 0) {
    //             setTimeout(function() {
    //                 if ($(window).scrollTop()+$(window).height() >= $('.menu__parallax__inner').offset().top + $('.menu__parallax__inner').height() ) {
    //                    var parheight = $(window).scrollTop()+$(window).height() - $menu_parallax.offset().top;
    //                    //parheight = Math.min( $parallaxInitialHeight , parheight);
    //                    $menu_parallax.css({height : parheight})
    //                 }
    //             } , 20 )
    //         }
    //     })
    // }

})

